<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col :cols="12" md="6">
          <v-checkbox v-model="paymentActive" label="Require payment upon submission"></v-checkbox>
        </v-col>
        <v-col v-if="paymentActive">
          <v-text-field v-model="department" label="Department to receive funds (required)"  dense outlined></v-text-field>
        </v-col>
      </v-row>
      <template v-if="paymentActive">
        <p>The following fields are for the FOAPAL for where accounting should credit this payment. If you do not know what to put here, please contact Accounting to obtain the correct fields.</p>
        <v-row>
          <v-col :cols="12" md="6" lg="3">
            <v-text-field v-model="foapal.fund" label="Fund" outlined dense></v-text-field>
          </v-col>
          <v-col :cols="12" md="6" lg="3">
            <v-text-field v-model="foapal.org" label="Org" outlined dense></v-text-field>
          </v-col>
          <v-col :cols="12" md="6" lg="3">
            <v-text-field v-model="foapal.acct" label="Account" outlined dense></v-text-field>
          </v-col>
          <v-col :cols="12" md="6" lg="3">
            <v-text-field v-model="foapal.prog" label="Program" outlined dense></v-text-field>
          </v-col>
        </v-row>
        <v-checkbox v-model="receipt" label="Include itemized receipt on confirmation page (beneath confirmation text)."></v-checkbox>
        <v-toolbar dense>
          <v-toolbar-title>Payment Rules</v-toolbar-title>
          <v-spacer></v-spacer>
          <payment-help></payment-help>
        </v-toolbar>
        <v-alert v-if="rules.length === 0" type="error">You have no payment rules. You must have at least one payment rule to define the amount to be paid.</v-alert>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(rule, index) in rules" :key="'rule-' + index">
            <v-expansion-panel-header>{{ index + 1 }}. {{ rule.name == null || rule.name === '' ? '**Unnamed Rule**' : rule.name }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <payment-rule :ref="ruleRef[index]" :rule="rule" :index="index" :inputs="formFields" @remove="removeRule(index)" @update="(r) => updateRule(r, index)"></payment-rule>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn class="mt-2" @click="rules.push({ name: '', amount: 0, field: null })">Add Rule</v-btn>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="paymentActive && (department === '' || rules.length === 0)" color="success" @click="save">
        <v-icon left>fal fa-save</v-icon>
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    formId: {
      type: String,
      required: true
    },
    revisionId: {
      type: String,
      required: true
    }
  },
  components: {
    PaymentRule: () => import('./PaymentRule'),
    PaymentHelp: () => import('./PaymentHelp')
  },
  setup (props, { root }) {
    const paymentActive = ref(false)
    const department = ref('')
    const foapal = ref({ fund: '', org: '', acct: '', prog: '' })
    const receipt = ref(false)
    const panel = ref(null)
    const rules = ref([])
    const ruleRef = ref([])

    const formFields = ref([])
    watch(() => props.revisionId, () => {
      if (props.formId != null && props.formId !== '') {
        root.$feathers.service('forms/base').get(props.formId).then(({ payment }) => {
          if (payment && 'active' in payment) {
            paymentActive.value = payment.active
            if ('department' in payment) department.value = payment.department
            if ('foapal' in payment) {
              foapal.value = payment.foapal
            } else {
              foapal.value = {
                fund: '',
                org: '',
                acct: '',
                prog: ''
              }
            }
            receipt.value = payment.receipt
            rules.value = payment.rules
          }
        })
      }
      if (props.revisionId != null && props.revisionId !== '') {
        root.$feathers.service('forms/revision').get(props.revisionId).then(async ({ sections }) => {
          formFields.value = []
          for (const { inputs } of sections) {
            for (const { label, input, options: optionId } of inputs) {
              console.log(label, input)
              if (input === 'select' || input === 'combobox' || input === 'autocomplete' || input === 'radio') {
                const { options } = await root.$feathers.service('forms/select-options').get(optionId)
                formFields.value.push({ label, input, options })
              } else if (input !== 'html-block') {
                formFields.value.push({ label, input })
              }
            }
          }
        })
      }
    })

    function updateRule (rule, index) {
      const temp = { ...rules.value[index], ...rule }
      rules.value.splice(index, 1, temp)
    }
    function removeRule (index) {
      rules.value.splice(index, 1)
    }

    async function save () {
      // Validate the rules first
      let valid = true
      for (let i = 0; i < ruleRef.value.length; i++) {
        if (!ruleRef.value[i].validate()) valid = false
      }
      if (valid) {
        const payment = { active: paymentActive.value }
        if (paymentActive.value) {
          payment.department = department.value
          payment.foapal = foapal.value
          payment.receipt = receipt.value
          payment.rules = rules.value
        }
        try {
          await root.$feathers.service('forms/base').patch(props.formId, { payment })
          root.$store.dispatch('main/snackbar', { color: 'success', text: 'Payment details saved successfully' })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving payment details: ' + e })
        }
      }
    }

    return {
      paymentActive,
      department,
      foapal,
      receipt,
      panel,
      rules,
      ruleRef,
      formFields,
      updateRule,
      removeRule,
      save
    }
  }
}
</script>
